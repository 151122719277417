jQuery(document).ready(function ($) {
  // Resize and move elements
  function khoozhResponsiveElements() {
    // Desktop width
    if ($(document).width() < 960) {
      // $('#order-review-wrapper').insertBefore($('.woocommerce-checkout-payment').prev());
    } else {
      // if (typeof wooPinCheckoutReview === "function") {
      //   wooPinCheckoutReview();
      // }
      // $('#order-review-wrapper').appendTo($('#customer_details .col-2'));
    }
  }
  khoozhResponsiveElements();
  $(window).resize(khoozhResponsiveElements);

  // init controller
  var controller = new ScrollMagic.Controller();

  // Create scene for every slide
  // Fade In elements
  var hiddenElements = $(".section-title, .attribute-list li, .card-single");
  hiddenElements.addClass("hidden");
  hiddenElements.each(function () {
    var myScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: "onEnter",
      offset: 120,
    })
      .setClassToggle(this, "shown")
      .addTo(controller);
  });

  // Fade Right elements
  var fadeRight = $(".cols-2 > *:nth-child(odd)");
  fadeRight.addClass("fade-right-before");
  fadeRight.each(function () {
    var myScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: "onEnter",
      offset: 120,
    })
      .setClassToggle(this, "fade-right")
      .addTo(controller);
  });

  // Fade Left elements
  var fadeLeft = $(
    ".cols-2 > *:nth-child(even), .lr-layout .container, li.product, .staggered-one-col-list li"
  );
  fadeLeft.addClass("fade-left-before");
  fadeLeft.each(function () {
    var myScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: "onEnter",
      offset: 120,
    })
      .setClassToggle(this, "fade-left")
      .addTo(controller);
  });

  // Fade Scale elements
  var fadeScale = $(".feature-image__main");
  fadeScale.addClass("fade-scale-before");
  fadeScale.each(function () {
    var myScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: "onEnter",
      offset: 300,
    })
      .setClassToggle(this, "fade-scale")
      .addTo(controller);
  });

  // Header scroll
  function headerScroll() {
    if ($(document).scrollTop() > 20) {
      $(".site-header").addClass("header-scroll");
    } else {
      $(".site-header").removeClass("header-scroll");
    }
  }
  headerScroll();
  $(document).on("scroll", headerScroll);

  /*
   * Hero Slider
   */
  // $(".hero-slider").ready(function() {
  //   $('.hero-slider').css({
  //     'opacity': '1',
  //     'visibility': 'visible'
  //   });
  // });

  $(".woocommerce-LoopProduct-link").on(
    "click",
    ".single-attribute-label",
    function (e) {
      e.preventDefault();

      var activateId = $(this).attr("data-activate-image");

      console.log();

      if (activateId != null) {
        $(this).parent().siblings(".attachment-woocommerce_thumbnail").hide();
        $("#archive-thumbnail-" + activateId).show();

        $(this).siblings().removeClass("active");
        $(this).addClass("active");
      }
    }
  );
});
